import logo from "./logo.svg";
import "./App.css";
import Nav from "./components/Nav";
import HomeContent from "./components/HomeContent";


function App() {
  return (
    <div className="main">
      <Nav />
      <HomeContent />
    </div>
  );
}

export default App;
