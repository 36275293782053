import React, { Component, useState, useEffect } from "react";
import "../css/home.css";
import photo from "../images/photo.png";
import course from "../images/course.png";
import { Link } from 'react-router-dom';

import cv from "../files/cv.pdf";
import Footer from "./Footer";

export default function HomeContent() {

  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [body, setBody] = useState('');


  const handleSubmit = (e) => {
    e.preventDefault();
    let bd = `Hello Mahesh, \n I am ${name}. \n\n ${body}`;

    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(bd)}&to=${encodeURIComponent("mail@maheshkhatri.com.np")}`;
    window.location.href = mailtoLink;
  };
  
  return (
    <div>
      <div className="home-content">
        <div className="intro">
          <h1>Hi, I am Mahesh,</h1>
          <h1>Software developer</h1>
          <p>
           This is me Mahesh Khatri, a software developer from Nepal.
           I am a detail oriented individual,
            proficient in various programming languages such as Java, Kotlin, Python, php, JavaScript and more and more and more...
             which have enabled me to build versatile application effectively interact with database.
          </p>
          <a href={cv} download rel="noopener noreferrer" target="_blank"><button className="button">Download Resume</button></a> 
        </div>
        <div className="photo">
          <img id="profile" src={photo} alt="Mahesh" />
        </div>
      </div>

      <div className="skills">
        <span className="titles">Frameworks</span>
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          <div className="skill-item">
            <label htmlFor="file">Android Native</label>
            <progress id="file" value="97" max="100">
              {" "}
              32%{" "}
            </progress>
          </div>
          <div className="skill-item">
            <label htmlFor="file">Flutter</label>
            <progress id="file" value="60" max="100">
              {" "}
              32%{" "}
            </progress>
          </div>
        </div>

        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}
        >
          <div className="skill-item">
            <label htmlFor="file">React</label>
            <progress id="file" value="60" max="100">
              {" "}
              32%{" "}
            </progress>
          </div>
          <div className="skill-item">
            <label htmlFor="file">Laravel</label>
            <progress id="file" value="80" max="100">
              {" "}
              32%{" "}
            </progress>
          </div>
        </div>
      </div>

      <div className="posts">
        <div className="mb-1">
          <span className="recent">Recent Posts</span>
          <span className="btn-text f-right">View All</span>
        </div>
        <div className="home-posts-list">
          <div className="card home-post-item">
            <h2>Making a design system from scratch</h2>
            <div className="post-info">
              <span>12 Feb 2020</span>
              <span>|</span>
              <span>Design, Pattern</span>
            </div>
            <span id="home-post-det">
              As a detail oriented individual, I am proficient in various
              programming languages such as Java, C++, Python and SQL, which
              have enabled me to build versatile application effectively
              interact with database.
            </span>
          </div>
          <div className="card home-post-item">
            <h2>Creating pixel perfect icons in Figma</h2>
            <div className="post-info">
              <span>12 Feb 2020</span>
              <span>|</span>
              <span>Figma, Icon Design</span>
            </div>
            <span id="home-post-det">
              As a detail oriented individual, I am proficient in various
              programming languages such as Java, C++, Python and SQL, which
              have enabled me to build versatile application effectively
              interact with database.
            </span>
          </div>
        </div>
      </div>
      <div className="works" id="works">
        <span className="titles">Featured works</span>

        <div className="home-works">
          <div className="work-item" tabIndex="0">
            <div className="work-item-div">
              <img className="work-item-img" src={course} />
            </div>
            <div className="work-item-content">
              <h3 style={{ marginTop: "10px", marginBottom: "10px" }}>
                CourseTube
              </h3>
              <Link to="/course-tube"><button className="button view-proj">View Project</button></Link>
              
            </div>
          </div>
        </div>
      </div>

      <div className="contact" id="contact">
        <h2>Have a project on mind? </h2>
        <h4>Contact Me </h4>
        <form onSubmit={handleSubmit}>
          <div className="contactor-det">
            <div className="input-div">
              <span>Enter full name</span>
              <input type="text" value={name} placeholder="Name" onChange={(e) => setName(e.target.value)} required/>
            </div>
            <div className="input-div">
              <span>Enter subject</span>

              <input value={subject}  type="text" onChange={(e) => setSubject(e.target.value)} placeholder="Subject"  required/>
            </div>
          </div>
          <div className="input-div">
            <span>Enter Project Details</span>
            <input value={body} id="input-message" onChange={(e) => setBody(e.target.value)}  type="text" placeholder="Message"  required/>
          </div>
          <button className="button" type="submit">Send Message</button>
        </form>
      </div>

    <Footer />
    </div>
  );
}

// $(document).ready(function() {
//   $(document).mousemove(function(e) {
//     var cpos = {
//       top: e.pageY + 10,
//       left: e.pageX + 10
//     };
//     $('#besideMouse').offset(cpos);
//   });
//   $('#start').click(startLoadingBM);
//   $('#stop').click(stopLoadingBM);
// });
