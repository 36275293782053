import React from 'react'
import github from "../images/github.png";

import Facebook from "../images/fb.svg";

import Fiverr from "../images/fiverr.svg";

import LinkedIn from "../images/linkedin.svg";

export default function Footer() {
  return (
        <div className="footer">
        <div>
          <a className="footer-link" target="_blank" href="https://github.com/MaheshPhuyal02">
          <img className="footer-icon github" src={github} alt="github" />
          </a>
          <a className="footer-link"  target="_blank" href="https://www.facebook.com/mahesh.khatri00/">
          <img className="footer-icon" src={Facebook} alt="facebook" />
          </a>
          <a className="footer-link" target="_blank" href="https://www.fiverr.com/maheshphuyal">
          <img className="footer-icon" src={Fiverr} alt="Fiverr" />
          </a>
          <a className="footer-link"  target="_blank" href="https://www.linkedin.com/in/mahesh-khatri-3650831a7/">
          <img className="footer-icon" src={LinkedIn} alt="linkedin" />
          </a>
        </div>
        <p><center>© 2024 Mahesh Khatri</center></p>
        <p><center>Site designed by : <a id="brisha" target="_blank" href="https://www.facebook.com/brisha.bajracharya">Brisha Bajracharya</a></center></p>
      </div>
  )
}
