import React from 'react'
import '../css/nav.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// work
import { faC, faCode } from '@fortawesome/free-solid-svg-icons'

// contact
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
// home
// import { faho } from '@fortawesome/free-regular-svg-icons'


export default function Nav(props) {

  let home = "/"
  let works = "#works"
  let contact = "#contact"

  if(props.page != "home"){
    works =  window.location.origin + "#works"
    contact = window.location.origin +  "#contact"
  }

  return (
    <div className='nav'
    >
        <ul>
            <Link to={home}><li> <FontAwesomeIcon className="nav-icon" icon={faHouse} />Home</li></Link>  
            <a href={works}><li> <FontAwesomeIcon className="nav-icon" icon={faCode} />Works</li></a>
            <a href={contact}><li><FontAwesomeIcon className="nav-icon" icon={faEnvelope} />Contact</li></a>
        </ul>
    </div>
  )
}
