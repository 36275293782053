import React from "react";

import Nav from "./Nav";

import "../css/project.css";

import a from "../images/course-tube-ss/1.png";
import b from "../images/course-tube-ss/2.png";
import c from "../images/course-tube-ss/3.png";
import d from "../images/course-tube-ss/4.png";
import e from "../images/course-tube-ss/5.png";
import f from "../images/course-tube-ss/6.png";
import g from "../images/course-tube-ss/7.png";
import Footer from "./Footer";


export default function CourseTube() {
  return (
    <div>
      <Nav />
      <div className="main-p">
       
          <h1>CourseTube</h1>
          <p>
            Coursetube is an android application that converts the youtube
            playlist into a learning course..
          </p>
          <p>- Made with native android.</p>
          <p>- Youtube api v3 </p>
          <p>
            - Firebase for database, push notifications, crashlytics, analytics
          </p>
          <p>- Admob with meta mediation</p>
        
        <div className="project-images zoom-anim-parent">
            <img src={a} alt="a" />
            <img src={b} alt="b " />
            <img src={c} alt="c" />
            <img src={d} alt="d" />
            <img src={e} alt="e" />
            <img src={f} alt="f" />
            <img src={g} alt="g" />
            </div>

      </div>
    <Footer />

    </div>
  );
}
